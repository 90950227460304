<template>
  <div class="card">
    <h1>{{ client.first_name }} {{ client.last_name }}</h1>
    <p>
      {{ client.street }} {{ client.street_no }}<br />
      {{ client.zip }} {{ client.city }}<br />
      {{ client.country.name }}
    </p>
    <p>
      Email:
      <a
        class="tw-whitespace-nowrap"
        :title="client.email"
        :href="`mailto:${client.email}`"
        >{{ client.email }}</a
      ><br />
      Mobile:
      <a
        class="tw-whitespace-nowrap"
        :title="client.mobile"
        :href="`tel:${client.mobile}`"
        >{{ client.mobile }}</a
      >
    </p>
    <p>Contact source: {{ client.contact_source.name }}</p>
    <p>
      Level:
      <Tag
        :value="client.level.name"
        :severity="mapLevelToSeverity(client.level.name)"
      />
    </p>
    <h2>Events attending/attended</h2>
    <event-list
      :events="client.event"
      :should-show-toolbar="false"
    ></event-list>
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue'
import useClient from '@/use/useClient.js'
import { reactive } from 'vue'
import { mapLevelToSeverity } from '@/helpers/clientHelpers.js'

export default {
  components: { EventList },
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const clientState = reactive({})
    const { fetchClient } = useClient()

    clientState.client = await fetchClient(props.clientId)

    return {
      client: clientState.client,
      mapLevelToSeverity
    }
  }
}
</script>
