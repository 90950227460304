export const mapLevelToSeverity = (level) => {
  switch (level) {
    case 'Beginner':
      return 'success'
    case 'Experienced':
      return 'warning'
    case 'Pro':
      return 'danger'
    default:
      return 'info'
  }
}
