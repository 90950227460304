<template>
  <Suspense>
    <ClientView :client-id="clientId" />
  </Suspense>
</template>

<script>
import ClientView from '@/components/ClientView.vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    ClientView
  },
  setup() {
    const route = useRoute()
    const clientId = route.params.id

    return {
      clientId
    }
  }
}
</script>
