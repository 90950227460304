import useSupabase from '@/use/useSupabase'
import { readonly, ref } from 'vue'
import { useToast } from 'primevue/usetoast'

const { supabase } = useSupabase()
const clients = ref([])
const client = ref({})

export default () => {
  const isLoading = ref(false)
  const toast = useToast()

  const fetchClients = async () => {
    isLoading.value = true
    try {
      const { data } = await supabase
        .from('client')
        .select(
          `
        *,
        level (
          name
        ),
        contact_source (
          name
        ),
        country (
          name
        )
      `
        )
        .order('id')
      clients.value = data
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }
  fetchClients()

  const fetchClient = async (id) => {
    try {
      const { data } = await supabase
        .from('client')
        .select(
          `
        *,
        country (*),
        level (*),
        contact_source (*),
        event ( * )
      `
        )
        .eq('id', id)
      client.value = data[0]
      return client.value
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
    }
  }

  const addClient = async (client) => {
    isLoading.value = true
    try {
      const newClient = await supabase.from('client').insert([client])
      return newClient
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Client added successfully.',
        life: 3000
      })
    }
  }

  const updateClient = async (client, id) => {
    isLoading.value = true
    try {
      await supabase.from('client').update(client).eq('id', id)
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Client updated successfully.',
        life: 3000
      })
    }
  }

  const deleteClient = async (id, silent = false) => {
    isLoading.value = true
    try {
      await supabase.from('client').delete().eq('id', id) // Deletes the client
    } catch (error) {
      console.error(error)
    } finally {
      if (!silent) {
        isLoading.value = false
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Client deleted.',
          life: 3000
        })
      }
    }
  }

  const deleteClients = async (ids) => {
    isLoading.value = true
    try {
      await Promise.all(
        ids.map(async (id) => {
          await deleteClient(id, true)
        })
      )
    } catch (error) {
      console.error(error)
    } finally {
      isLoading.value = false
      toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Selected clients deleted.',
        life: 3000
      })
    }
  }

  return {
    addClient,
    clients: readonly(clients),
    client: readonly(client),
    fetchClients,
    fetchClient,
    updateClient,
    deleteClient,
    deleteClients,
    isLoading: readonly(isLoading)
  }
}
